import React, { useEffect, useState } from 'react'
import './styles.css'
import { Box, CircularProgress } from '@mui/material'
import { getShuffledItems } from '../../hooks/shuffle'

function Features({ coins }) {
    const [trendyCoins, setTrendyCoins] = useState()
    const [newCoins, setNewCoins] = useState()
    const [topGainersCoins, seTopGainersCoins] = useState()

    useEffect(() => {
        setTrendyCoins(getShuffledItems(coins))
        setNewCoins(getShuffledItems(coins))
        seTopGainersCoins(getShuffledItems(coins))
    }, [coins])

    console.log(coins)

    return (
        <Box className='featuresWrapper'>
            <Box className='featuresCard'>
                {
                    coins.length !== 0 ?
                        <>
                            <h2>Trendy Coins</h2>
                            {
                                trendyCoins?.map((coin) => {
                                    return (
                                        <Box className='featuresCardContent'>
                                            <Box className='featuresCardContentBox'>
                                                <Box style={{ display: "flex", alignItems: "center", gap: '8px' }}>
                                                    <img src={coin.image} width={30} />
                                                    <Box>
                                                        <h3 style={{ margin: '0px', color: 'var(--white)' }}>{coin?.name}</h3>
                                                        <h6 style={{ marginTop: "8px", color: 'var(--white)', marginBottom: '0px' }}>${coin?.total_volume}</h6>
                                                    </Box>
                                                </Box>
                                                <Box className={coin?.market_cap_change_percentage_24h < 0 ? 'featuresnegChange' : 'featuresposChange'}>
                                                    <h5 style={{ margin: 0 }}> ${coin?.market_cap_change_percentage_24h}</h5>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </>
                        :
                        <Box className='featuresLoading'>
                            <CircularProgress />
                        </Box>
                }
            </Box>
            <Box className='featuresCard'>
                {
                    coins.length !== 0 ?
                        <>
                            <h2>New Coins</h2>
                            {
                                newCoins?.map((coin) => {
                                    return (
                                        <Box className='featuresCardContent'>
                                            <Box className='featuresCardContentBox'>
                                                <Box style={{ display: "flex", alignItems: "center", gap: '8px' }}>
                                                    <img src={coin.image} width={30} />
                                                    <Box>
                                                        <h3 style={{ margin: '0px', color: 'var(--white)' }}>{coin?.name}</h3>
                                                        <h6 style={{ marginTop: "8px", color: 'var(--white)', marginBottom: '0px' }}>${coin?.total_volume}</h6>
                                                    </Box>
                                                </Box>
                                                <Box className={coin?.market_cap_change_percentage_24h < 0 ? 'featuresnegChange' : 'featuresposChange'}>
                                                    <h5 style={{ margin: 0 }}> {coin?.market_cap_change_percentage_24h}%</h5>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </>
                        :
                        <Box className='featuresLoading'>
                            <CircularProgress />
                        </Box>
                }
            </Box>
            <Box className='featuresCard'>
                {
                    coins.length !== 0 ?
                        <>
                            <h2>Top Gainers</h2>
                            {
                                topGainersCoins?.map((coin) => {
                                    return (
                                        <Box className='featuresCardContent'>
                                            <Box className='featuresCardContentBox'>
                                                <Box style={{ display: "flex", alignItems: "center", gap: '8px' }}>
                                                    <img src={coin.image} width={30} />
                                                    <Box>
                                                        <h3 style={{ margin: '0px', color: 'var(--white)' }}>{coin?.name}</h3>
                                                        <h6 style={{ marginTop: "8px", color: 'var(--white)', marginBottom: '0px' }}>${coin?.total_volume}</h6>
                                                    </Box>
                                                </Box>
                                                <Box className={coin?.market_cap_change_percentage_24h < 0 ? 'featuresnegChange' : 'featuresposChange'}>
                                                    <h5 style={{ margin: 0 }}> ${coin?.market_cap_change_percentage_24h}</h5>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </>
                        :
                        <Box className='featuresLoading'>
                            <CircularProgress />
                        </Box>
                }
            </Box>
        </Box>
    )
}

export default Features