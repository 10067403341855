import { CircularProgress, Container, Grid } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getNews } from '../../api'
import './styles.css'

function Blogs() {
    const [news, setNews] = useState([]);
    useEffect(
        () => {
            getNews().then(res => {
                setNews(res.items)
            }).catch(error => console.log(error))
        }, [])
    // console.log(news.slice(0, 1))

    return (
        <>
            <div className="blogWrapper" >
                <Container style={{ padding: 0 }}>
                    <Grid container fluid >
                        <Grid lg={12} className="blogTitle">
                            <p>Latest News</p>
                        </Grid>
                        <Grid className='scrollBlogContainer'>
                            {
                                !news? 
                                 <CircularProgress />
                                : 
                                news?.map((item) => (
                                    <Grid lg={12} md={12} sm={12} xs={30} key={item.id}>
                                        <div className='blogContainer'>
                                            <img alt="blogImage" src={item.media.thumbnail.url} style={{ width: '100%', height:180  , borderRadius:"24px 24px 0px 0px"}} />
                                            <h3>{item.title}</h3>
                                            <div className='footerNewsContainer'>
                                                <a href={item.link} >Read more</a>
                                            </div>
                                        </div>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Blogs