import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/system';
import './styles.css'
function CryptoCurrency() {

    const FAQ = [
        {
            "question": "What is a cryptocurrency trading platform and its functionality?",
            "answer": "A cryptocurrency trading platform is a digital marketplace where traders can buy, sell, and exchange cryptocurrencies. It facilitates transactions by providing a secure environment, tools for analysis, and options for trading different digital currencies against each other or fiat currencies."
        },
        {
            "question": "Key factors to consider when selecting a cryptocurrency trading platform?",
            "answer": "When choosing a cryptocurrency trading platform, consider factors like security measures, transaction fees, the range of available currencies, trading volume, user interface, customer support, and the platform's regulatory compliance."
        },
        {
            "question": "What does the term 'deep liquidity' mean in crypto trading?",
            "answer": "Deep liquidity in crypto trading refers to a market's ability to handle large transactions without significant impacts on the asset's price. This is beneficial as it allows for quicker transactions at stable prices."
        },
        {
            "question": "Steps to create a trading account on ExodiaWallet.com for spot trading?",
            "answer": "To open a trading account on ExodiaWallet.com for spot trading, start by registering on the platform, verifying your identity, setting up security features like two-factor authentication, and then depositing funds into your account to start trading."
        },
        {
            "question": "How do I begin trading cryptocurrencies on a Spot Trading platform?",
            "answer": "To start trading cryptocurrencies on a Spot Trading platform, ensure you have a verified account with funds. Familiarize yourself with the platform's interface, decide on a trading strategy, and begin by placing small trades to understand market movements."
        },
        {
            "question": "What types of orders can be placed in Spot Trading?",
            "answer": "In Spot Trading, you can typically place several types of orders, including market orders (buy/sell at the current market price), limit orders (set a specific price to buy/sell), and stop-loss orders (sell when the price drops to a certain level)."
        },
        {
            "question": "How can I find current exchange rates on Spot Trading platforms?",
            "answer": "Exchange rates on Spot Trading platforms can be found on the trading interface, usually under a section like 'Markets' or 'Exchange.' Rates are continuously updated to reflect the latest market prices."
        }
    ]
    
   
    return (
        <Container className="cryptoCurrencyWrapper" sx={{ mt: 4 }}>
            <h1>
                FAQ
            </h1>
            {FAQ.map((faq, index) => (
                <Accordion key={index} className='cryptoCurrencyAccordion' sx={{
                    borderBottom: '1px solid white',
                    mb: 1,
                }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color:"white"}}/>}>
                        <Typography variant="h5">{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Typography variant='h6'>
                            {faq.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    )
}

export default CryptoCurrency