import { Box, Container, Divider, Grid } from '@mui/material'
import React from 'react'
import './style.css'
import benefits from '../../assets/images/benefits.svg'
import smoothUx from '../../assets/images/smooth-ux.svg'
import easyAcc from '../../assets/images/easy-account.svg'
import multipleAcc from '../../assets/images/multiple-accounts.svg'
import tight from '../../assets/images/tight-spreads.svg'

function MobileApp() {

    const data=[
        {
            icon :smoothUx , 
            title:'Smooth UX',
            desc:"Accelerate Your Trading Success with Our Intuitive Trading Terminal"
        },
        {
            icon :easyAcc , 
            title:'Easy account top up',
            desc:"Instantly Fund Your Strategy by Transferring Funds from Your Wallet"
        },
        {
            icon :multipleAcc , 
            title:'Multiple markets',
            desc:"Access the majority of the crypto universe with a large list of available markets"
        },
        {
            icon :tight , 
            title:'Tight spreads',
            desc:"Optimise your trading costs with narrow spreads on all available markets"
        },
    ]
  
    return (
        <Box className='mobileAppWrapper'>
            <Box className='mobileAppContainer'>
                <h1>Why Choose Our Spot Trading Platform for Your Crypto Needs?</h1>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box className='mobileAppContent'>
                            {
                                data?.map((d)=>{
                                    return(
                                        <Box className='mobileAppContentData'>
                                            <img src={d.icon} alt={d.title} width={50} height={50}/>
                                            <h2>{d.title}</h2>
                                            <h3>{d.desc}</h3>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={benefits} alt='benefits' width='100%' height='100%'/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default MobileApp