import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.css';
import Cookies from 'universal-cookie';
import video from '../../assets/videos/video.mp4';

function Body() {
    const cookie = new Cookies();
    const [randomNumber, setRandomNumber] = useState('');

    useEffect(() => {
        // Function to generate a random number within a specified range
        const generateRandomNumber = () => {
            const min = 1000000; // Minimum value for random number
            const max = 100000000; // Maximum value for random number
            const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
            return randomNum;
        };

        // Function to format number with commas
        const formatNumberWithCommas = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        };

        // Generate and format the random number
        const number = generateRandomNumber();
        const formattedNumber = formatNumberWithCommas(number);

        // Set the formatted number with a dollar sign
        setRandomNumber(`$${formattedNumber}`);
    }, []); 

    return (
        <Box className='bodyWrapper'>
            <Box className='bodyVideoContainer'>
                <video width="100%" height="100%" autoPlay muted loop>
                    <source src={video} type="video/mp4" />
                </video>
                <Box className='overlayText'>
                    <h1>Empower Your Crypto Journey</h1>
                    <p>
                        Discover an Intuitive and Powerful Crypto Trading Platform Designed for
                        Both Novices and Experts. With a Suite of Professional Tools and Access to
                        Deep Liquidity Pools, Our Platform Ensures Seamless and Efficient Trading Experiences.
                    </p>
                    <Button className='overlayTextButton'>
                        Start Trading Now
                    </Button>
                    <Box className='overlayTextVolume'>
                        24H Volume : {randomNumber}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Body;
